import axios from 'axios';
import { useCancelToken } from 'hooks';
import _ from 'lodash';
import { useCallback, useState } from 'react'

const useLoadTruckData = (httpRequest, { truckId }) => {
  const [data, setData] = useState([]);
  const { getCancelToken } = useCancelToken();
  const [isLoading, setIsLoading] = useState(false);

  const loadTruck = useCallback(async () => {
    setIsLoading(true);
    const response = await httpRequest({
      method: 'get',
      url: `/trucks/${truckId}`,
      cancelToken: getCancelToken(),
    }).catch((error) => {
      setIsLoading(axios.isCancel(error))
    });

    if (response) {
      const items = _.get(response, 'data.data', []);
      setData(items);
      setIsLoading(false);
      return items;
    }
    return [];

  }, [getCancelToken, truckId, httpRequest]);
  
  return ({ loadTruck, data, isLoading, setData });
}

export default useLoadTruckData