import React from 'react';
import _ from 'lodash';

function withUserInfo(WrappedComponent) {
  return class extends React.Component {
    isAuthenticated = () => Boolean(localStorage.getItem('evl-is-authenticated'));

    user = () => {
      const json = localStorage.getItem('evl-profile');
      return json ? JSON.parse(json) : {};
    };

    getRoles = () => {
      const user = this.user();
      if (_.isEmpty(user)) return null;
      return _.map(_.get(user, 'data.roles', []), (r) => r.name);
    }

    getPermissions = () => {
      const user = this.user();
      if (_.isEmpty(user)) return null;
      return _.get(user, 'data.permissions', []);
    }

    getProps = () => {
      const permissions = this.getPermissions();
      const roles = this.getRoles();
      const user = this.user();

      return ({
        ...this.props,
        permissions,
        roles,
        user: { ...user, permissions, roles },
        isAuthenticated: this.isAuthenticated(),
      });
    }

    render() {
      return (
        <WrappedComponent {...this.getProps()} />
      );
    }
  };
}

export default withUserInfo;
