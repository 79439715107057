import React from 'react'
import Th from './th';

const Thead = ({ columnList }) => (
  <thead>
    <tr>
      {columnList.map((item) => (
        <Th key={item} title={item} />
        ))}
    </tr>
  </thead>
);

export default Thead;