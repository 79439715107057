import React from 'react';

const TheadSort = ({
  columnList, sortFieldsWithKeys, onClickSort, getSortIcon, isLoading,
}) => (
  <thead>
    <tr>
      {columnList.map((column) => (
        <th
          key={column}
          className="px-2 py-3 text-left"
        >
          <button
            type="button"
            onClick={() => sortFieldsWithKeys[column] ? onClickSort(column) : null}
            disabled={isLoading}
            className="cursor-default"
          >
            <span
              className={`flex text-left text-xs font-bold text-gray-500 tracking-wider gap-2
              ${sortFieldsWithKeys[column] ? 'hover:underline cursor-pointer' : ''}`}
            >
              {column}
              {sortFieldsWithKeys[column] ? getSortIcon(column) : ''}
            </span>
          </button>
        </th>
      ))}
    </tr>
  </thead>
);

export default TheadSort;