import { FormTextarea } from 'components/form'
import RadioGroup from 'components/radio-group'
import Upload from 'components/upload'
import UploadWrapperDND from 'components/upload/upload-wrapper-dnd'
import React, { useState } from 'react'
import handleDroppedFileInput from 'components/drag-and-dpop/index';
import { FormLabel } from 'modules/dispatches/modalCreateDispatch'
import { DVIRActions } from './dvir'
import PropTypes from 'prop-types';

const options = [
  {id: 'pass', label: 'Pass'},
  {id: 'fail', label: 'Fail'},
]

const DVIRSubItemView = ({ item, passValue, onSelectSubItem, action }) => {
  const [notes, setNotes] = useState(passValue?.notes || "");
  const [passOrFail, setPassOrFail] = useState(passValue?.passOrFail || "");
  const [photos, setPhotos] = useState(passValue?.photos || null);

  const onFileDrop = (file) => {
    const newFiles = handleDroppedFileInput(file, 'image', true);
    if (newFiles) {
      setPhotos(newFiles);

      if (passOrFail || action === DVIRActions.truckIssues) {
        onSelectSubItem(item, newFiles, notes, passOrFail);
      }
    };
  }

  return (
    <div className="mb-4">
      <div className="text-sm font-bold">
        {item?.name}
      </div>
      <div className="">
        {item?.description}
      </div>
      {item?.mandatory && action === DVIRActions.preTrip && (
        <FormLabel isRequired label="This image is mandatory" className="mt-2" />
      )}
      <UploadWrapperDND onDrop={onFileDrop} customClass="my-2">
        <Upload
          files={photos || null}
          handleFileInput={(e) => {
            const photos = Array.from(e);
            setPhotos(photos);
            if (passOrFail || action === DVIRActions.truckIssues) {
              onSelectSubItem(item, photos, notes, passOrFail);
            }
          }}
          extension="image"
          isMultiple
          uniqueId={item?.id}
          isHighlighted={passOrFail === 'fail' && notes?.length === 0 && (!photos || photos?.length === 0)}
        />
      </UploadWrapperDND>
      <FormTextarea
        label=""
        placeholder="Add notes here, if any"
        value={notes}
        handleValue={(val) => {
          setNotes(val);
          if (passOrFail) {
            onSelectSubItem(item, photos, val, passOrFail);
          }
        }}
        isHighlighted={passOrFail === 'fail' && notes?.length === 0 && (!photos || photos?.length === 0)}
      />
      {action !== DVIRActions.truckIssues && (
        <RadioGroup
          onChange={(val) => {
            setPassOrFail(val)
            onSelectSubItem(item, photos, notes, val);
          }}
          items={options}
          className="flex flex-col gap-2 mt-2"
          defaultSelected={passOrFail || ""}
        />
      )}
    </div>
  )
}

DVIRSubItemView.propTypes = {
  item: PropTypes.object.isRequired,
  onSelectSubItem: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  passValue: PropTypes.object,
}

DVIRSubItemView.defaultProps = {
  passValue: {},
}

export default DVIRSubItemView