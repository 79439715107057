import React from 'react';
import PropTypes from 'prop-types';

const PageHeaderWrapper = ({ children }) => (
  <div className="sm:flex sm:items-center sm:justify-between mb-5 w-full">
    {children}
  </div>
);

PageHeaderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageHeaderWrapper;
