import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { XCircleIcon } from '@heroicons/react/solid';

const ErrorList = ({ list }) => (
  <ul className="list-disc pl-5 space-y-1">
    { _.map(_.flatten(_.values(list)), (error) => <li key={error}>{error}</li>) }
  </ul>
);

const ValidationErrors = ({ errors, addClass }) => (
  <div className={`rounded-md bg-red-50 p-4 mt-4 ${addClass}`}>
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800" data-testid="error">
          {errors.message}
        </h3>
        <div className="mt-2 text-sm text-red-700">
          { !_.isEmpty(errors) && <ErrorList list={errors.errors || {}} /> }
        </div>
      </div>
    </div>
  </div>
);

ErrorList.propTypes = {
  list: PropTypes.object.isRequired,
};

ValidationErrors.propTypes = {
  errors: PropTypes.object.isRequired,
  addClass: PropTypes.string,
};

ValidationErrors.defaultProps = {
  addClass: '',
};

export default ValidationErrors;
