import React, { PureComponent, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withUserInfo from 'hocs/with-user-info';
import WithPermission from '../with-permission';
import SideNavigation from 'components/navigation';
import { checkNavPermission, hasPermission } from 'utils';
import PageLoading from 'components/page/page-loading';
import { navigation } from 'components/navigation';
import { DVIR, DVIRConfig, DVIRRules } from 'modules/dvir/dvir-ca';
import { Clocks } from 'modules/clocks';
import KnowledgeBase from 'modules/faqs/knowledge-base';

const Home = React.lazy(() => import('../home'));
const Dashboard = React.lazy(() => import('../dashboard'));
const TripsIssues = React.lazy(() => import('../payroll/trips-issues'));
const PendingApprovals = React.lazy(() => import('../approvals'));
const TrucksList = React.lazy(() => import('../trucks'));
const DriverTrucksView = React.lazy(() => import('../trucks/driver-trucks-view'));
const RunAssignment = React.lazy(() => import('../run-assignment'));
const PayrollsList = React.lazy(() => import('../payroll'));
const InvoicesList = React.lazy(() => import('../invoices'));
const CandidatesList = React.lazy(() => import('../candidates'));
const IncidentsList = React.lazy(() => import('../incidents'));
const AlertsList = React.lazy(() => import('../alerts'));
const DriverExpiringDocuments = React.lazy(() => import('../reporting/pages/expiring-documents/DriverExpiringDocuments'));
const TruckExpiringDocuments = React.lazy(() => import('../reporting/pages/expiring-documents/TruckExpiringDocuments'));
const DriverPayrollAnalysisList = React.lazy(() => import('../driver-payroll-analysis'));
const Mmr = React.lazy(() => import('../reporting/pages/mmr'));
const TruckAnalysis = React.lazy(() => import('../reporting/pages/truck-analysis'));
const TruckMilesAnalysis = React.lazy(() => import('../reporting/pages/truck-miles-analysis'));
const LocationAnalysis = React.lazy(() => import('../reporting/pages/location-analysis'));
const DriverMilesAnalysis = React.lazy(() => import('../reporting/pages/driver-miles-analysis'));
const DriverAnalysis = React.lazy(() => import('../reporting/pages/driver-analysis'));
const Vendors = React.lazy(() => import('../reporting/pages/vendors'));
const UnpaidTrips = React.lazy(() => import('../reporting/pages/unpaid-trips'));
const Warranty = React.lazy(() => import('../reporting/pages/warranty'));
const FuelPurchase = React.lazy(() => import('../reporting/pages/fuel-purchase'));
const ReportingList = React.lazy(() => import('../reporting'));
const DispatchesProvider = React.lazy(() => import('modules/dispatches/dispatches-provider'));
const FAQs = React.lazy(() => import('../faqs'));
const UserForm = React.lazy(() => import('../users/form'));
const UsersList = React.lazy(() => import('../users'));
const UserPermissions = React.lazy(() => import('../users/permissions'));
const Vacation = React.lazy(() => import('../vacation'));
const UpdateBrand = React.lazy(() => import('../brand/update-brand'));
const CompanyForm = React.lazy(() => import('../companies/form'));
const CompaniesList = React.lazy(() => import('../companies'));
const CompanySettingsForm = React.lazy(() => import('../companies/settings'));
const CandidateForm = React.lazy(() => import('../candidates/form'));
const HubContacts = React.lazy(() => import('../hub-contacts'));
const HubContactForm = React.lazy(() => import('../hub-contacts/form'));
const HubForm = React.lazy(() => import('../hubs/form'));
const HubsList = React.lazy(() => import('../hubs'));
const TruckForm = React.lazy(() => import('../trucks/form'));
const TruckReport = React.lazy(() => import('../trucks/report'));
const TruckDocs = React.lazy(() => import('../trucks/truck-docs'));
const DriversList = React.lazy(() => import('../drivers'));
const DriverReport = React.lazy(() => import('../drivers/report'));
const DriverDocs = React.lazy(() => import('../drivers/driver-docs'));
const PayrollsChange = React.lazy(() => import('../payroll/change'));
const InvoicesForm = React.lazy(() => import('../invoices/form'));
const RolesList = React.lazy(() => import('../roles-permissions'));
const RolePermissionsForm = React.lazy(() => import('../roles-permissions/form'));
const IncidentForm = React.lazy(() => import('../incidents/form'));
const Logs = React.lazy(() => import('../logs'));
const ViewProfile = React.lazy(() => import('../profile/view-profile'));
const PayRates = React.lazy(() => import('../payrates'));
const SmsReplies = React.lazy(() => import('../alerts/sms-replies'));
const TruckCount = React.lazy(() => import('../truck-count'));
const Regions = React.lazy(() => import('modules/regions/regions'));
const TruckList = React.lazy(() => import('components/truck-list-lessor/truck-list'));
const PerDiemReport = React.lazy(() => import('../reporting/pages/per-diem/PerDiemReport'));
const ChatList = React.lazy(() => import('modules/chat/chat-list'));
const TruckMiles = React.lazy(() => import('modules/reporting/pages/truck-miles/truck-miles'));

class PrivateArea extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      redirectPath: '/home',
      isClosed: false,
    };
  }

  toggleVisibility = () => {
    const {
      isClosed,
    } = this.state;
    localStorage.setItem('isClosed', !isClosed);
    this.setState((prevState) => ({ isClosed: !prevState.isClosed }));
  }

  componentDidMount() {
    const { permissions } = this.props;
    const isClosed = localStorage.getItem('isClosed')?.toString() === 'true';
    const filteredNavigation = navigation.filter((nav) => checkNavPermission(nav, permissions));
    const firstTabRef = _.get(filteredNavigation, '[0].href', '/home');
    this.setState({ isClosed, redirectPath: firstTabRef });
  }

  render() {
    const { isAuthenticated, permissions } = this.props;
    const { isClosed, redirectPath } = this.state;
    if (!isAuthenticated) return <Redirect to="/" />;
    return (
      <>
        <div className={`lg:h-screen h-full flex flex-col lg:flex-row ${isClosed ? 'closedSidebar' : ''}`}>
          <SideNavigation isClosed={isClosed} toggleVisibility={this.toggleVisibility} />
          <div className="lg:flex lg:flex-col lg:w-0 lg:h-full h-5/6 flex-1">
            <Switch>
                <Route exact path="/home">
                  <Suspense fallback={<PageLoading />}>
                    <WithPermission
                      onRoute
                      permissionsCheck={['AnyResource', 'CompaniesResources', 'CanDrive', 'Home']}
                    >
                      <Home />
                    </WithPermission>
                  </Suspense>
                </Route>
              <Route path="/home/pay-approve=:pay_id?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission
                    onRoute
                    permissionsCheck={['AnyResource', 'CompaniesResources', 'CanDrive', 'Home']}
                  >
                    <Home />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/userprofile">
                <Suspense fallback={<PageLoading />}>
                  <ViewProfile />
                </Suspense>
              </Route>
              <Route exact path="/company-settings">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <UpdateBrand />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/companies/create">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource', 'CompaniesResources']}>
                    <CompanyForm action="Create" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/companies/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource', 'CompaniesResources']}>
                    <CompaniesList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/companies/:id(\d+)/edit">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource', 'CompaniesResources']}>
                    <CompanyForm action="Edit" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/companies/:id(\d+)/settings">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource', 'CompaniesResources']}>
                    <CompanySettingsForm />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/users/create">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource', 'CompaniesResources']}>
                    <UserForm action="Create" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/users/:id(\d+)/edit">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'AnyResource']}>
                    <UserForm action="Edit" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/users/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource', 'CompaniesResources']}>
                    <UsersList />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/users/:id(\d+)?/permissions">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <UserPermissions />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/candidates/create">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Recruiting']}>
                    <CandidateForm action="Create" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/candidates/:id(\d+)/edit">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Recruiting']}>
                    <CandidateForm action="Edit" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/candidates/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Recruiting']}>
                    <CandidatesList />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/locations/create">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource']}>
                    <HubForm action="Create" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/locations/:id(\d+)/edit">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource']}>
                    <HubForm action="Edit" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/locations/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'AnyResource']}>
                    <HubsList />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/trucks/create">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'TruckAdd']}>
                    <TruckForm action="Create" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/trucks/report/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'TruckView']}>
                    <TruckReport />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/trucks/:id(\d+)/edit">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'TruckEdit']}>
                    <TruckForm action="Edit" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/trucks/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  {
                    hasPermission(['CompaniesResources', 'TruckView'], permissions)
                      ? (
                        <WithPermission
                          onRoute
                          permissionsCheck={['CompaniesResources', 'TruckView']}
                        >
                          <TrucksList />
                        </WithPermission>
                      )
                      : (
                        <WithPermission onRoute permissionsCheck={['CanDrive']}>
                          <DriverTrucksView />
                        </WithPermission>
                      )
                  }
                </Suspense>
              </Route>
              <Route exact path="/truck-documents">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource', 'CompaniesResources']}>
                    <TruckDocs />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/drivers/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'DriverView']}>
                    <DriversList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/drivers/report/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'DriverView']}>
                    <DriverReport />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/driver-documents">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['DriverAddDocs', 'CompaniesResources']}>
                    <DriverDocs />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/payrolls/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'CanDrive', 'PayrollView']}>
                    <PayrollsList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/payrolls/change">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'PayrollView']}>
                    <PayrollsChange />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/missing-revenue/:active_tab?/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'MissingRevenue']}>
                    <TripsIssues />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/invoices/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Invoice']}>
                    <InvoicesList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/invoices/invoice=:invoice_number?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Invoice']}>
                    <InvoicesList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/invoices/upload">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Invoice']}>
                    <InvoicesForm />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/roles">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['RoleListingOwn']}>
                    <RolesList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/roles/create">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <RolePermissionsForm action="Create" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/roles/:id(\d+)/edit/permissions">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <RolePermissionsForm action="Edit" />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/dashboard">
                <Suspense fallback={<PageLoading />}>
                    <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Dashboard']}>
                      <Dashboard />
                    </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/incidents/create">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Incident']}>
                    <IncidentForm action="Create" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/incidents/:id(\d+)/edit">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Incident']}>
                    <IncidentForm action="Edit" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/incidents/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Incident']}>
                    <IncidentsList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/incidents/incident=:incident_id?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Incident']}>
                    <IncidentsList />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/faqs/:id(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'CanDrive']}>
                    <FAQs />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/knowledge-base">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'AnyResource']}>
                    <KnowledgeBase />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/vacation">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission
                    onRoute
                    permissionsCheck={['CompaniesResources', 'VacationListingOwn', 'CanDrive']}
                    >
                    <Vacation />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/payrates">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission
                    onRoute
                    permissionsCheck={['CompaniesResources', 'PayRatesDriverListing']}
                  >
                    <PayRates />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/drivers/expiring-documents/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <DriverExpiringDocuments />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/drivers/per-diem/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <PerDiemReport />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/trucks/expiring-documents/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <TruckExpiringDocuments />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/truck-mpg-tracker">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <TruckMiles />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/driver-payroll-analysis/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'DriverPayrollAnalysis']}>
                    <DriverPayrollAnalysisList />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/mmr/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <Mmr />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/truck-analysis/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'TruckAnalysis']}>
                    <TruckAnalysis />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/truck-miles-analysis/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'TruckMilesAnalysis']}>
                    <TruckMilesAnalysis />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/location-analysis">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'LocationAnalysis']}>
                    <LocationAnalysis />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/driver-miles-analysis/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'DriverMilesAnalysis']}>
                    <DriverMilesAnalysis />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/driver-analysis/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'DriverAnalysis']}>
                    <DriverAnalysis />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/vendors/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <Vendors />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/unpaid-trips/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'UnpaidTrips']}>
                    <UnpaidTrips />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/warranty/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <Warranty />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting/fuel-purchase">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources']}>
                    <FuelPurchase />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/truck-count">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource']}>
                    <TruckCount />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/reporting">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={[
                      'CompaniesResources', 'DriverPayrollAnalysis', 'DriverRevenueAnalysis', 'DriverMilesAnalysis',
                      'DriverAnalysis', 'TruckRevenueAnalysis', 'TruckMilesAnalysis', 'TruckAnalysis',
                      'LocationAnalysis', 'UnpaidTrips',
                    ]}>
                    <ReportingList />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/alerts/:active_tab?/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Alert']}>
                    <AlertsList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/sms-replies/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Alert']}>
                    <SmsReplies />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/approvals/:active_tab?/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'PendingApproval']}>
                    <PendingApprovals />
                  </WithPermission>
                </Suspense>
              </Route>

              <Route exact path="/logs/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['AnyResource']}>
                    <Logs />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/contacts">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'HubContact']}>
                    <HubContacts />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/contacts/create">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'HubContact']}>
                    <HubContactForm action="Create" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/contacts/:id(\d+)/edit">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'HubContact']}>
                    <HubContactForm action="Edit" />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/run-assignment/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'AssignmentsSA']}>
                    <RunAssignment />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/regions">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'Region']}>
                    <Regions />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/dispatches/:page(\d+)?">
                <Suspense fallback={<PageLoading />}>
                  <DispatchesProvider redirectPath={redirectPath} />
                </Suspense>
              </Route>
              <Route exact path="/truck-list">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['Lessor']}>
                    <TruckList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/dvir-list/:truck_no?/:truck_check_id?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'CanDrive', 'DVIR']}>
                    <DVIR />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/dvir-config">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'DVIR']}>
                    <DVIRConfig />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/dvir-rules">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'DVIR']}>
                    <DVIRRules />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/clocks">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'CanDrive', 'Clock']}>
                    <Clocks />
                  </WithPermission>
                </Suspense>
              </Route>
              <Route exact path="/chat/:chatId?">
                <Suspense fallback={<PageLoading />}>
                  <WithPermission onRoute permissionsCheck={['CompaniesResources', 'CanDrive']}>
                    <ChatList />
                  </WithPermission>
                </Suspense>
              </Route>
              <Redirect from="*" to={redirectPath} />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

PrivateArea.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  permissions: PropTypes.array.isRequired,
};

export default _.flowRight([
  withUserInfo,
])(PrivateArea);
