import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ValidationError from '../../components/notifications/validation-errors';
import Errors from '../../components/notifications/errors';

import Logo from '../../components/logo';
import getAPIClient from '../../services/api';

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      email: '',
      isOK: false,
      isLoading: false,
    };
  }

  onEmailChange = (event) => {
    const { value: email } = event.target;
    this.setState({
      email,
    });
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const apiClient = getAPIClient();
    const { email } = this.state;
    const { history } = this.props;
    this.setState({
      isLoading: true,
    });

    const response = await apiClient.post('reset-password', {
      email,
    }).catch((error) => {
      this.setState({
        errors: error?.response?.data || [],
        message: 'Something went wrong, try again later.',
        email: '',
        isOK: false,
        isLoading: false,
      });
    });
    if (response) {
      const { data: { message, status } } = response;
      this.setState({
        errors: [],
        message,
        email: '',
        isOK: status === true,
        isLoading: false,
      });
      if (status !== false) {
        history.push({
          pathname: '/',
          flash: {
            type: 'success',
            message,
          },
        });
      }
    }
  }

  render() {
    const {
      email, message, isOK, isLoading, errors,
    } = this.state;
    return (
      <div className="min-h-full h-full">

        <div className="min-h-full h-full flex lg:grid lg:grid-cols-2">
          <div className="flex-1 flex flex-col justify-center py-10 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full lg:w-96">
              <div>
                <Logo />
                <br />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Can&apos;t log in?</h2>
              </div>
              {
                (!_.isEmpty(errors) && (
                  <ValidationError errors={errors} />
                )) || (message
                && (
                  <div>
                      { isOK ? message : (<Errors errors={[message]} />)}
                  </div>
                ))
              }
              <div className="mt-8 relative">
                {isLoading && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="animate-spin border-b-2 rounded-full h-10 w-10 border-gray-900" />
                  </div>
                )}
                <div className={`${isLoading ? 'opacity-50' : ''} mt-6`}>
                  <form action="#" method="POST" className="space-y-6">
                    <div>
                      <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1
                        focus-within:ring-green-regular focus-within:border-green-regular"
                      >
                        <label htmlFor="email" className="block text-xs font-medium text-gray-900">
                          We&apos;ll send a recovery link to
                        </label>
                        <input
                          onChange={this.onEmailChange}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full border-0 outline-none p-0 bg-transparent text-gray-900
                            placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="Enter your email"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600">
                        or
                        {' '}
                        <Link to="/login" className="font-medium text-green-regular hover:text-green-hover">Sign In</Link>
                      </p>
                    </div>
                    <div>
                      <button
                        onClick={this.onSubmit}
                        disabled={_.isEmpty(email) || isLoading}
                        type="button"
                        className="w-full flex justify-center py-2 px-4 border border-transparent
                          rounded-md shadow-sm text-sm font-medium text-white bg-green-regular
                          hover:bg-green-hover focus:outline-none focus:ring-2 focus:ring-offset-2
                          focus:ring-green-regular"
                      >
                        Send recovery link

                      </button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1 py-8 px-12 w-full text-center bg-green-light">
            <img
              className="inset-0 w-full object-cover mx-auto login-img"
              src="/assets/login-image.jpg"
              alt=""
            />

            <Link
              to="/"
              className="bg-white color-green-regular hover:text-green-hover
              rounded-md px-6 py-3 inline-block"
            >
              Get started
            </Link>

          </div>
        </div>

      </div>
    );
  }
}

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ResetPassword;
