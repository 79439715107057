import React from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';

import getAPIClient from '../../services/api';
import withUser from '../with-user';

/* eslint-disable react/prop-types */

function withAPI(WrappedComponent) {
  return compose(withUser)(class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        httpGetLoading: false,
        httpGetDone: false,
        httpGetFailed: false,
        authorized: true,
      };
    }

    reLogin = async () => {
      const canReLogin = localStorage.getItem('canReLogin');
      if (canReLogin === '1') {
        localStorage.setItem('canReLogin', '0');
        const tokens = JSON.parse(localStorage.getItem('evl-tokens'));
        const { refresh_token } = tokens || '';
        if (refresh_token?.length > 0) {
          const clientSecret = process.env.REACT_APP_PASSPORT_CLIENT_SECRET;
          const clientId = process.env.REACT_APP_PASSPORT_CLIENT_ID;
          const apiClient = getAPIClient();
          apiClient
            .post('oauth/token', {
              grant_type: 'refresh_token',
              client_id: clientId,
              client_secret: clientSecret,
              refresh_token,
            })
            .catch(() => this.setState({ authorized: false }))
            .then(async (response) => {
              const { data } = response;
              if (data) {
                const {
                  storeTokens, getProfile, storeProfile, setIsAuthenticated,
                } = this.props;
                storeTokens(data);
                const profile = await getProfile();
                storeProfile(profile);
                setIsAuthenticated(true);
                localStorage.setItem('canReLogin', '1');
                window.location.reload();
              }
            });
        } else {
          this.setState({ authorized: false });
          localStorage.setItem('canReLogin', '1');
        }
      }
    };

    httpRequest = async ({
      method: httpMethod,
      url,
      data,
      tag: mTag = '',
      noToken,
      cancelToken = null,
      responseType,
    }) => {
      const tokens = JSON.parse(localStorage.getItem('evl-tokens')) || {};
      const timeout = url === 'payrolls/import-multiple' ? 60000 : 30000;
      const fn = _.get(
        getAPIClient((!noToken && tokens && tokens.access_token)
          ? tokens.access_token
          : undefined, timeout, cancelToken, responseType),
        httpMethod,
      );
      const method = _.startCase(httpMethod);
      const tag = _.startCase(mTag);
      this.setState({
        [`http${method}Loading${tag}`]: true,
      });
      const result = await fn(url, data).catch((error) => {
        const code = _.get(error, 'response.status', 404);
        if (code === 401) {
          this.reLogin();
        } else {
          this.setState({
            [`http${method}Loading${tag}`]: false,
            [`http${method}Done${tag}`]: true,
            [`http${method}Failed${tag}`]: true,
            [`http${method}FailReason${tag}`]: {
              code,
              errors: _.get(error, 'response.data.errors'),
              message: _.get(error, 'response.data.message'),
            },
          });
          throw error;
        }
      });
      if (result) {
        this.setState({
          [`http${method}Loading${tag}`]: false,
          [`http${method}Done${tag}`]: true,
          [`http${method}Failed${tag}`]: false,
          [`http${method}FailReason${tag}`]: undefined,
        });
        return result;
      }
      return null;
    }

    render() {
      const rest = {
        httpRequest: this.httpRequest,
      };
      const { authorized } = this.state;
      return authorized ? <WrappedComponent {...this.props} {...rest} {...this.state} /> : <Redirect to="/logout" />;
    }
  });
}

export default withAPI;
