import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

const ModalWrapper = React.forwardRef(({
  title, subtitle, open, children, setOpen, customWidth, className="sm:col-span-6 px-2",
  modalOverflow = "overflow-hidden",
}, ref) => (
  <Transition.Root show={open} as={Fragment} data-testid="modal">
    <Dialog
      as="div"
      className="fixed z-10 inset-0 h-full"
      onClose={setOpen}
    >
      <div
        ref={ref}
        className="items-center justify-center h-full
        text-center block px-2 overflow-y-auto"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
            ${modalOverflow} shadow-xl transform transition-all my-8 sm:align-middle sm:p-6 ${customWidth?.length > 0
              ? customWidth : `sm:w-full sm:max-w-3xl`}`}
          >
            <button type="button" className="float-right" onClick={() => setOpen(false)}>
              <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-black-400" aria-hidden="true" />
            </button>
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {subtitle}
                  </p>
                </div>
              </div>
            </div>
            <div className={className}>
              {children}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
));

ModalWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  setOpen: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  customWidth: PropTypes.string,
};

ModalWrapper.defaultProps = {
  subtitle: '',
  customWidth: '',
};

export default ModalWrapper;
