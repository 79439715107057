import React from 'react';
import PropTypes from 'prop-types';

const Th = ({ title, customWidth }) => {
  const styles = { width: `${customWidth}px` };
  return (
    <th
      scope="col"
      className="px-2 py-3 text-left text-xs font-bold
        text-gray-500 tracking-wider"
        style={customWidth ? styles : {}}
    >
      {title}
    </th>
  );
}

Th.propTypes = {
  title: PropTypes.string.isRequired,
  customWidth: PropTypes.number,
};

export default Th;
