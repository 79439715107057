import { getFormattedDateAPI } from 'components/dateHandle'
import DotsDropdown from 'components/dots-dropdown'
import { PageLoadingIndicator } from 'components/loading-indicator'
import RulesDetails from 'components/modal/rules-details'
import { NoResults } from 'components/notifications'
import { PageHeaderBtn, PageHeaderWrapper, PageTitle, PageWrapper } from 'components/page'
import Pagination from 'components/pagination'
import { TableWrapper, Thead } from 'components/table'
import { withAPI } from 'hocs'
import _ from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import useLoadRules from '../hooks/useLoadRules'

const columnList = ['Company', 'Start', 'End', 'Random Items', 'Status'];

const DVIRRules = ({ httpRequest }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, loadRules, pagination} = useLoadRules(httpRequest, currentPage);

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedRulesItem, setSelectedRulesItem] = useState({});
  const [action, setAction] = useState('');

  const getStatus = (startDate, endDate) => {
    if (typeof endDate === 'string' && typeof startDate === 'string') {
      try {
        const currentDate = moment(new Date());
        const formattedEnd = moment(endDate);
        const formattedStart = moment(startDate)
        const diffWithEndDate = currentDate.diff(formattedEnd);
        const diffWithStartDate = currentDate.diff(formattedStart);
        
        const isSameDate = formattedEnd.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD');

        if (diffWithStartDate === diffWithEndDate) {
          return 'Active';
        } else if (diffWithStartDate > 0 && diffWithEndDate < 0) {
          return 'Active';
        } else if (diffWithStartDate > 0 && isSameDate) {
          return 'Active';
        } else if (diffWithEndDate > 0) {
          return 'Expired';
        } else if (diffWithStartDate < 0) {
          return 'Pending';
        }
      } catch (error) {
        console.log(error)
        return ''
      }
    }
    return ''
  }

  const deleteRule = async (item) => {
    const response = await httpRequest({
      method: 'delete',
      url: `/dvir-rules/${item.id}`,
    })

    if (response) {
      loadRules();
    }
  }
  
  const menuItems = [
    {
      permissionCheck: [],
      label: 'Details',
      callback: (item) => {
        setAction('View');
        setSelectedRulesItem(item);
        setIsDetailsOpen(true);
      },
    },
    {
      permissionCheck: [],
      label: 'Edit',
      callback: (item) => {
        setAction('Edit');
        setSelectedRulesItem(item);
        setIsDetailsOpen(true);
      },
    },
    {
      permissionCheck: [],
      label: 'Delete',
      callback: (item) => {
        deleteRule(item);
      },
    },
  ];

  return (
    <PageWrapper>
      <RulesDetails
        open={isDetailsOpen}
        setOpen={() => { setIsDetailsOpen(false); setSelectedRulesItem({}); }}
        rulesItem={selectedRulesItem}
        isLoading={isLoading}
        action={action}
        refreshData={loadRules}
      />
      <PageHeaderWrapper>
        <PageTitle title="DVIR Rules" withFAQsPage />
        <PageHeaderBtn
          label='Create'
          onClick={() => { setIsDetailsOpen(true); setSelectedRulesItem({}); setAction('Create'); }}
        />
      </PageHeaderWrapper>
      <TableWrapper>
        <PageLoadingIndicator loading={isLoading} />
        {data?.length === 0 && !isLoading && (<NoResults />)}
        <table className="divide-y divide-gray-200 overflow-x-hidden overflow-y-visible w-full">
          {data?.length > 0 && (
            <Thead columnList={columnList} />
          )}
          <tbody className={`w-full divide-y ${isLoading ? 'opacity-50' : ''}`}>
            {data?.length > 0 && data.map((item) => (
              <tr key={item?.id} className="text-sm">
                <td className="pl-2 py-3">{item?.company?.name}</td>
                <td className="pl-2 py-3">{getFormattedDateAPI(item?.from)}</td>
                <td className="pl-2 py-3">{getFormattedDateAPI(item?.to)}</td>
                <td className="pl-2 py-3">{item?.random_items}</td>
                <td className="pl-2 py-3">{getStatus(item?.from, item?.to)}</td>
                <td className="px-2 p-2 w-max text-right">
                  <DotsDropdown {...{ id: item, menuItems }} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          {...pagination}
          currentPage={currentPage}
          noPaginationLinks
          onChange={(page) => setCurrentPage(page)}
        />
      </TableWrapper>
    </PageWrapper>
  )
}

export default _.flowRight([withAPI])(DVIRRules);