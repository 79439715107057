import { SearchIcon } from '@heroicons/react/outline';
import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

const PageSearch = ({ onChange, isDisabled, placeholder, className, search }) => {
  const [value, setValue] = useState(search);

  const onChangeInput = (val) => {
    setValue(val);
    onChange(val);
  }

  return (
  <div className={`relative ${className || 'w-full md:w-max mr-auto' }`}>
    <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
      <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </div>
    <input
      value={value}
      onChange={(e) => onChangeInput(e.target.value)}
      id="search"
      name="search"
      disabled={isDisabled}
      className="md:block flex flex-1 ls:w-max xl:w-max w-full
                  bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3
                  text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900
                  focus:placeholder-gray-400 sm:text-sm"
      placeholder={placeholder}
      type="search"
    />
  </div>
)};

PageSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  search: PropTypes.string,
  isDisabled: PropTypes.bool,
};

PageSearch.defaultProps = {
  className: '',
  search: '',
  placeholder: 'Search',
  isDisabled: false,
};

export default PageSearch;
