import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PageLoadingIndicator } from 'components/loading-indicator';

const CurrentSettlementCard = ({
  label, amount, payMile, percent, color, isLoading,
}) => {
  const [isHover, setIsHover] = useState(false);
  const formatAmount = (val) => (_.isString(val) ? val.split('.')[0] : val);
  return (
    <div
      className={`w-44 h-20 bg-white hover:bg-${color}-300 gap-2
    flex flex-col snap-start rounded-md transition-all relative py-2`}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <PageLoadingIndicator loading={isLoading} />
      <div className="flex items-center justify-between gap-1 w-full">
        {!isLoading && (
          <>
            <h2
              className={`ml-2 px-2 ${isHover ? 'bg-white' : `bg-${color}-300`}
              w-max rounded-md text-${color}-900 transition-all whitespace-nowrap`}
            >
              {
                label && (
                  String(label)?.replace(':', '')
                )
              }
            </h2>
            <div className="w-full text-right pr-2">
              {amount !== null && (
                <>
                  <h2 className="font-medium">
                    {label === 'MPG:' ? amount : formatAmount(amount)}
                  </h2>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="ml-2 w-40 flex gap-1 justify-between">
        <div className="w-full">
          {payMile !== null && !isLoading && (
            <h2>
              {payMile}
              /mile
            </h2>
          )}
        </div>
        <div className="w-full flex flex-col items-end">
          {percent !== null && !isLoading && (
            <h2>
              {percent}
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};
CurrentSettlementCard.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  payMile: PropTypes.string,
  percent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  isLoading: PropTypes.bool,
};

CurrentSettlementCard.defaultProps = {
  label: '',
  amount: '',
  payMile: '',
  percent: '',
  color: '',
  isLoading: false,
};

export default CurrentSettlementCard;
