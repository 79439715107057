const allExtensions = {
  image: {
    jpg: true,
    jpeg: true,
    png: true,
    heic: true,
  },
  doc: {
    pdf: true,
    jpg: true,
    jpeg: true,
    png: true,
    heic: true,
  },
  doc_and_image: {
    pdf: true,
    jpg: true,
    jpeg: true,
    png: true,
    doc: true,
    docx: true,
    heic: true,
  },
  doc_image_and_video: {
    pdf: true,
    jpg: true,
    jpeg: true,
    png: true,
    doc: true,
    docx: true,
    heic: true,

    mp4: true,
    mov: true,
    webm: true,
  },
  payroll_import: {
    csv: true,
  },
  faq: {
    jpg: true,
    jpeg: true,
    png: true,
    heic: true,
    mp4: true,
  }
};

const handleDroppedFiles = (files, type, getAll = false) => {
  const extensions = (type && allExtensions[type]) ? allExtensions[type] : allExtensions.doc_and_image;
  const acceptedFiles = (fls) => Array.from(fls)?.filter((fl) => extensions[fl?.name?.split('.')?.pop()?.toLowerCase()]);
  if (acceptedFiles(files).length > 0) return getAll ? acceptedFiles(files) : acceptedFiles(files)[0];
  return getAll ? [] : null;
};

export default handleDroppedFiles;

const allAcceptExtensions = {
  image: '.jpg,.jpeg,.png,.heic',
  doc: '.pdf,.jpg,.jpeg,.png,.heic',
  doc_and_image: '.pdf,.jpg,.jpeg,.png,.doc,.docx,.heic',
  payroll_import: '.csv',
  doc_image_and_video: '.pdf,.jpg,.jpeg,.png,.doc,.docx,.mp4,.mov,.webm,.heic',
};

export const getAcceptExtensions = (type) => allAcceptExtensions[type || 'doc_and_image'];

const allExtensionsToView = {
  image: 'JPG, HEIC, JPEG or PNG',
  doc: 'PDF, JPG, HEIC, JPEG or PNG',
  doc_and_image: 'PDF, JPG, HEIC, JPEG, PNG, DOC or DOCX',
  payroll_import: 'CSV',
  doc_image_and_video: 'PDF, JPG, JPEG, HEIC, PNG, DOC, DOCX, MP4, MOV, or WebM',
};

export const viewExtensions = (type) => allExtensionsToView[type || 'doc_and_image'];
