import { fileExtensions, videoExtensions } from 'components/modal/file-preview';
import _ from 'lodash';

export const hasPermission = (permissionsCheck, userPermissions) => (
  _.intersection(permissionsCheck, userPermissions).length > 0);

export const fieldToText = (str) => str[0]?.toUpperCase() + str?.replace(/_/g, ' ')?.slice(1).toLowerCase();

export const isNotMobile = () => (window.screen.availWidth > 1023);

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const listToQuery = (list, join) => list?.map((i) => i.value)?.join(join) || '';

export const getCompanyRequest = (comp, signWith = '&') => {
  if (_.isArray(comp)) {
    const updatedList = comp.filter(({ value }) => value !== '');
    const queryList = updatedList?.map(({ value }, index) => `companies[${index}]=${value}`) || [];
    return queryList.length > 0 ? signWith.concat(queryList.join('&')) : '';
  }
  return '';
};

export const getDriverLabel = (driv) => `${driv?.user?.name_formatted} - ${driv?.driver_no}`;

export const getFullName = (item) => `${item?.first_name} ${item?.last_name}`;

export const getUserLabel = (userObj) => (`${userObj?.first_name} ${
  userObj?.middle_name ? `${userObj?.middle_name} ` : ''}${userObj?.last_name}`);

export const setLocalStorage = (id, data) => window.localStorage.setItem(id, JSON.stringify(data));

export const getPaginationLabels = (currentPage, lastPage, maxPerPage) => {
  const pagesList = [];

  if (lastPage <= maxPerPage) {
    Array(lastPage).fill(lastPage).forEach((i, index) => pagesList.push(index + 1));
  } else if (maxPerPage >= 5) {
    const pagesPlaceholderList = Array(maxPerPage).fill(maxPerPage);

    const leftSideCloser = (currentPage / lastPage) < 0.5;
    const minMargin = leftSideCloser ? currentPage - 1 : lastPage - currentPage;

    if (minMargin <= maxPerPage - 3) {
      const dotIndex = leftSideCloser ? maxPerPage - 2 : 1;
      pagesPlaceholderList.forEach((i, index) => {
        if (index === 0) {
          pagesList.push(1);
        } else if (index === maxPerPage - 1) {
          pagesList.push(lastPage);
        } else if (index === dotIndex) {
          pagesList.push('...');
        } else {
          pagesList.push(leftSideCloser ? index + 1 : lastPage - maxPerPage + index + 1);
        }
      });
    } else {
      pagesPlaceholderList.forEach((i, index) => {
        if (index === 0) {
          pagesList.push(1);
        } else if (index === maxPerPage - 1) {
          pagesList.push(lastPage);
        } else if (index === 1 || index === maxPerPage - 2) {
          pagesList.push('...');
        } else {
          const onSides = Math.floor((maxPerPage - 4) / 2);
          pagesList.push(currentPage - onSides + index - 2);
        }
      });
    }
  } else pagesList.push(currentPage);

  return pagesList;
};

export const getToggleSelectorVal = (val) => (val === undefined ? '' : String(+Boolean(val)));

export const getFormattedCompArr = (companies) => [{ value: '', label: 'All Companies' }]
  .concat(companies.map((i) => ({ value: i.id, label: i.name })));

export const formatCurrency = (value, currency = '$') => {
  const amount = Number(value);
  if (_.isNaN(amount)) return `0 ${currency}`;
  return `${amount.toFixed(2)} ${currency}`;
};

export const scrollToTop = (ref) => {
  if (typeof ref?.current?.scrollTo === 'function') {
    ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const isValidInteger = (value) => !_.isNaN(+value);

export const isMyProfile = (id) => {
  const profile = JSON.parse(localStorage.getItem('evl-profile'));
  const myId = _.get(profile, 'data.id', 0);
  return _.toNumber(myId) === _.toNumber(id);
}

export const checkNavPermission = (navObj, userPermissions) => {
  if (!_.isEmpty(navObj.permissions) && navObj?.isMultiPermissions) {
    const commonPermissions = _.intersection(userPermissions, navObj.permissions);
    return commonPermissions.length === navObj.permissions?.length;
  }
  return (_.isEmpty(navObj.permissions)
    ? !_.intersection(userPermissions, navObj.skipPermissions).length > 0
    : _.intersection(userPermissions, navObj.permissions).length > 0);
}

export const formatPaginationMeta = (pagination = {}, withLinks = false) => {
  if (_.isEmpty(pagination) || (typeof pagination !== 'object' || _.isArray(pagination))) return ({});
  const { last_page, per_page, total, from, to, links } = pagination;
  return ({
    totalPages: last_page,
    perPage: per_page,
    totalRecords: total,
    from,
    to,
    paginationLinks: withLinks ? links : [],
  });
}

export const getFormattedCustomSelectArr = (obj, condition = () => true) => {
  if (typeof obj === 'object' && !_.isEmpty(obj)) {
    return Object.keys(obj)
      .filter(condition)
      .map((item) => ({
        id: item,
        name: obj[item],
      }));
  }
  return [];
}

export const onChangeMultipleSelect = (opts, isAllCompanies, companies) => {
  const find = opts.filter((i) => i.value === '');
  if (isAllCompanies && _.isEmpty(find)) {
    return ({
      selectedCompanies: [],
      isAllComp: false,
    });
  } else if (!_.isEmpty(find) && !isAllCompanies) {
    return ({
      selectedCompanies: companies,
      isAllComp: true,
    });
  } else if (opts.length + 1 === companies.length && _.isEmpty(find) && !isAllCompanies) {
    return ({
      selectedCompanies: companies,
      isAllComp: true,
    });
  } else if ((opts.length < companies.length) && !_.isEmpty(find)) {
    return ({
      selectedCompanies: opts.filter((i) => i.value !== ''),
      isAllComp: false,
    });
  }
  return ({
    selectedCompanies: opts,
    isAllComp: false,
  });
}

export const getSplittedArrayByCharacter = (array, pathToValue, character = ',') => {
  if (_.isArray(array)) {
    if (!pathToValue) {
      return array
        .slice(0, -1)
        .map((item) => `${item}${character}`)
        .concat(_.last(array));
    }
    return _.map(
      array.slice(0, -1),
      (obj) => `${_.get(obj, pathToValue, '')},`)
      //set last value without character.
      .concat(_.get(_.last(array), pathToValue, ''));
  }
  return [];
}

export const getFormattedHubs = (hubs) => {
  if (_.isArray(hubs)) {
    return hubs?.map((hub) => ({
      value: hub?.id,
      label: `${hub?.name} (${hub.external_id})`,
    }));
  }
  return [];
}

export const formatObjForDropDown = (obj) => {
  if (!_.isEmpty(obj)) {
    return Object.keys(obj).map((key) => ({ id: key, name: obj[key] }));
  }
  return [];
}

export function getUserFullName(user, defaultValue = '') {
  const values =  [_.get(user, "last_name"), _.get(user, "first_name")].filter(Boolean);
  return _.isEmpty(values) ? defaultValue : values.join(' ');
}

export function createIframe({ width = "100%", height = "350px", src, className }) {
  const iframe = document.createElement('iframe');
  iframe.src = src;
  iframe.width = width;
  iframe.height = height;
  iframe.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share";
  iframe.className = className;
  iframe.allowFullscreen = true;
  return iframe;
}

export const isVideoExt = (path) => {
  if (!_.isEmpty(path)) {
    const ext = _.last(path.split('.'));
    return videoExtensions.has(ext);
  }
  return false;
}

export const isFileExt = (path) => {
  if (!_.isEmpty(path)) {
    const ext = _.last(path.split('.'));
    return fileExtensions.has(ext);
  }
  return false;
}

export const isIosDevice = () => navigator.userAgent.match(/(iPad|iPhone|iPod)/g);

export const isAndroidDevice = () => /(android)/i.test(navigator.userAgent);

export const prettifyFloat = (number, digits = 2) => {
  if (_.isNumber(number)) {
    return parseFloat(number.toFixed(digits))
  }
  return 0;
}