import React, { useState } from 'react'
import ModalWrapper from './modal-wrapper';
import PropTypes from 'prop-types';
import { getFormattedFullDate } from 'components/dateHandle';
import LocationMap from './location-map';
import { Location } from 'components/icons';
import { DVIRCard } from 'components/cards';
import ResolutionsListViewModal from 'modules/dvir/dvir-ca/modals/resolutions-list-view';

const DVIRViewDetails = ({ open, setOpen, dvir }) => {
  
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false);

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title=""
      className="w-full md:overflow-visible overflow-auto"
      customWidth="max-w-full md:max-w-7xl w-full"
      modalOverflow="overflow-y-visible"
    >
      <ResolutionsListViewModal open={isResolutionModalOpen} setOpen={(opn) => setIsResolutionModalOpen(opn)} data={dvir?.resolutions || []} />
      {Boolean(dvir?.lng && dvir?.lat) && (
        <LocationMap
          location={[dvir?.lat, dvir?.lng]}
          open={isMapOpen}
          setOpen={() => setIsMapOpen(false)}
        />
      )}
      <div className="text-center text-lg leading-6 font-medium text-gray-900">
        <div className="flex justify-between border-b gap-2">
          <div className="font-bold">
            DVIR
          </div>
          <div className="flex gap-2 h-max flex-wrap justify-items-end items-center">
            {dvir?.resolutions?.length > 0 && (
              <TitleInfo
                label="Resolutions:" info={dvir?.resolutions?.length}
                onClick={() => setIsResolutionModalOpen(true)}
                className="text-green-regular text-sm font-bold pb-0.5 cursor-pointer hover:underline"
              />
            )}
            <TitleInfo label="Status:" info={dvir?.status?.label} />
            <TitleInfo label="Truck No:" info={dvir?.truck_no} />
            <TitleInfo label="Driver:" info={dvir?.user?.name} />
            {Boolean(dvir?.lng && dvir?.lat) && (
              <>
                <div className="text-xs text-black-500 mr-1">
                  Location:
                </div>
                <button type="button" className="pb-2.5" onClick={() => setIsMapOpen(true)}>
                  <Location />
                </button>
              </>
            )}
            <TitleInfo label="Date:" info={getFormattedFullDate(dvir?.created_at)} className="text-black-800 font-bold text-sm pb-0.5" />
          </div>
        </div>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-2 mt-2">
        {dvir?.dvir_items?.length > 0 && dvir?.dvir_items?.map((item) => (
          <DVIRCard
            key={item?.id}
            category={item?.category?.name}
            name={item?.name}
            passOrFail={item?.pass}
            notes={item?.notes}
            files={item?.photos}
          />
        ))}
      </div>
    </ModalWrapper>
  )
}

DVIRViewDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  dvir: PropTypes.object.isRequired,
}

export default DVIRViewDetails;

const TitleInfo = ({
  label,
  info,
  className="text-green-regular text-sm font-bold pb-0.5",
  onClick = null,
}) => (
  <div onClick={onClick} className="flex items-center">
    <div className="text-xs text-black-500 mr-1">
      {label}
    </div>
    <div className={className}>
      {info}
    </div>
  </div>
);