import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { compose } from 'recompose';

const MenuSelectList = ({ children, ...other }) => (
  <>
    <components.MenuList {...other} className="w-full">
      <p className="px-3 pb-1 text-gray-600">Choose your options</p>
      <div className="w-max shadow-sm">
        {children}
      </div>
    </components.MenuList>
  </>
);

MenuSelectList.propTypes = {
  children: PropTypes.array,
};

MenuSelectList.defaultProps = {
  children: [],
};

export default compose(
)(MenuSelectList);
