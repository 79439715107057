import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { compose } from 'recompose';

const SelectOptionsCheckbox = ({ label, isSelected, ...other }) => (
  <div>
    <components.Option
      {...other}
      className={`${isSelected ? 'bg-green-50' : 'bg-gray-100'} mt-2.5 relative w-full`}
    >
      <div
        className={`${isSelected ? 'bg-green-500' : 'bg-gray-200'} w-0.5 h-full absolute left-0 top-0`}
      />
      <label
        className="focus:ring-indigo-500 h-4 w-4
      text-indigo-600 border-gray-300 rounded-full relative"
        htmlFor="label"
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
          className={`rounded-full w-5 h-5 border-0 ${isSelected ? 'text-green-500' : ''}`}
        />
        <span className="ml-2 text-sm font-medium text-gray-900" id="label">{label}</span>
      </label>
    </components.Option>
  </div>
);

SelectOptionsCheckbox.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string,
};

SelectOptionsCheckbox.defaultProps = {
  label: '',
};

export default compose(
)(SelectOptionsCheckbox);
