import { getDate, getFormattedDateAPI } from 'components/dateHandle';
import { FormHandlersWrapper, FormInput, FormSave } from 'components/form';
import { PageLoadingIndicator } from 'components/loading-indicator';
import { ValidationsErrors } from 'components/notifications';
import RegularDropdown from 'components/regular-dropdown';
import { Td, Thead, Tr } from 'components/table';
import { withAPI } from 'hocs';
import useLoadCompanies from 'hooks/api/useLoadCompanies';
import _ from 'lodash';
import { DVIRRulesDropDown } from 'modules/dvir/dvir-ca';
import useLoadDVIRQuestions from 'modules/dvir/hooks/useLoadDVIRQuestions';
import React, { Fragment, useState } from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { scrollToTop } from 'utils';
import ModalWrapper from './modal-wrapper'

const columnList = ['Name'];

const RulesDetails = ({ open, setOpen, rulesItem, isLoading, action = 'View', httpRequest, refreshData }) => {

  const [rules, setRules] = useState(rulesItem?.rules || []);
  const [start, setStart] = useState(rulesItem?.from || '');
  const [end, setEnd] = useState(rulesItem?.to || '');
  const [isPostLoading, setIsPostLoading] = useState(false)
  const [randomItems, setRandomItems] = useState(rulesItem?.random_items || '0');
  const [selectedCompany, setSelectedCompany] = useState(rulesItem?.company_id || null);
  const {
    data: DVIRItems,
    isLoading: isDVIRItemsLoading,
    setData: setDVIRItems,
  } = useLoadDVIRQuestions(httpRequest, selectedCompany, false);
  const { data: companies } = useLoadCompanies(httpRequest);
  const [errors, setErrors] = useState({});

  const modalRef = useRef(null);

  const onChangeRules = (newRules) => {
    if (_.isArray(rules)) {
      const existItemIndex = rules.findIndex((item) => +item?.dvir_item_id === newRules?.id);
      if (existItemIndex === -1) {
        setRules((prev) => [...prev, { dvir_item_id: newRules?.id, required: '1'}]);
      } else {
        setRules((prev) => {
          const newRules = _.cloneDeep(prev);
          newRules?.splice(existItemIndex, 1);
          return newRules;
        })
      }
    }
  }

  useEffect(() => {
    setRules(rulesItem?.rules || []);
    setStart(rulesItem?.from || '');
    setEnd(rulesItem?.to || '');
    setRandomItems(rulesItem?.random_items || '0');
    setSelectedCompany(rulesItem?.company_id || null);
    return () => {
      setErrors({});
      setDVIRItems([]);
    };
  }, [rulesItem, setDVIRItems])

  const getData = () => {
    const data = {};

    data.company_id = selectedCompany;
    data.from = start || getFormattedDateAPI(new Date());
    data.to = end || getFormattedDateAPI(new Date());
    data.rules = rules;
    data.random_items = +randomItems;
    return data;
  }

  const isValid = () => {
    const errors = [];

    const isNumber = !_.isNaN(_.toNumber(randomItems));
    if (!isNumber) errors.push('Random items should be a number.');

    if (!selectedCompany) errors.push('Select the company.')

    if (errors.length > 0) {
      const messages = {};
      errors.forEach((item, index) => {
        messages[index] = item;
      })
      setErrors({ errors: messages });
      return false;
    }

    return true;
  }

  const onSaveRules = async () => {
    const url = action === 'Edit'
      ? `/dvir-rules/${rulesItem?.id}`
      : `/dvir-rules`;

    const data = getData();
    if (!isValid(data)) {
      scrollToTop(modalRef)
      return;
    }
    
    setIsPostLoading(true);
    const response = await httpRequest({
      method: action === 'Edit' ? 'patch' : 'post',
      data,
      url,
    }).catch((error) => setErrors(error.response.data))
      .finally(() => setIsPostLoading(false))

    if (response) {
      setOpen(false);
      refreshData();
    }
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={action !== 'Create' ? `Number of random items: ${rulesItem?.random_items || '0'}` : ''}
      ref={modalRef}
      customWidth="sm:max-w-3xl sm:w-full overflow-visible"
    >
      {!_.isEmpty(errors) && (
        <ValidationsErrors errors={errors} />
      )}
      {action !== 'View' && (
        <div className="flex gap-2 text-sm font-medium text-gray-700">
          <div className="w-full">
            <div className="mb-1">Start</div>
            <ReactDatePicker
              selected={getDate(start)}
              onChange={(data) => setStart(getFormattedDateAPI(data))}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                        block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-full">
            <div className="mb-1">End</div>
            <ReactDatePicker
              selected={getDate(end)}
              onChange={(data) => setEnd(getFormattedDateAPI(data))}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                        block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="w-full">
            <FormInput
              label="Random Items"
              value={randomItems || ''}
              placeholder="Select items count"
              handleValue={(id, val) => setRandomItems(val)}
            />
          </div>
        </div>
      )}
      {action === 'Create' && (
        <div className="w-1/3 mt-2">
          <div className="mb-1 text-sm font-medium text-gray-700">Company</div>
          <RegularDropdown
            items={companies}
            onChange={setSelectedCompany}
            selectedValue={selectedCompany}
            placeholder="Select Company"
            customMargin="ml-0"
            isFull
          />
        </div>
      )}
      <PageLoadingIndicator loading={isLoading || isDVIRItemsLoading} />
      <table className="divide-y divide-gray-200 overflow-x-hidden overflow-y-visible w-full">
        {selectedCompany && DVIRItems?.length > 0 && (
          <Thead columnList={columnList} />
        )}
        <tbody className={`w-full divide-y ${isLoading ? 'opacity-50' : ''}`}>
          {selectedCompany && DVIRItems?.length > 0 && DVIRItems.map((category) => (
            <Fragment key={category?.id}>
              <Tr>
                <Td className="pl-7 py-3">{category?.name}</Td>
              </Tr>
              <DVIRRulesDropDown
                categoryId={category?.id}
                rules={rules}
                onChangeRules={onChangeRules}
                action={action}
              />
            </Fragment>
          ))}
        </tbody>
      </table>
      {action !== 'View' && (
        <FormHandlersWrapper>
          <FormSave onClick={onSaveRules} isLoading={isPostLoading || isLoading} isDisabled={!selectedCompany} />
        </FormHandlersWrapper>
      )}
    </ModalWrapper>
  )
}

export default _.flowRight([withAPI])(RulesDetails);