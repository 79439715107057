import { getFormattedDateAPI } from 'components/dateHandle'
import DotsDropdown from 'components/dots-dropdown'
import { PageLoadingIndicator } from 'components/loading-indicator'
import ConfirmationModal from 'components/modal/confirmation-modal'
import { MultipleSelector } from 'components/multiple-selector'
import { NoResults, ValidationsErrors } from 'components/notifications'
import { PageHeaderBtn, PageHeaderDataFilter, PageHeaderWrapper, PageTitle, PageWrapper } from 'components/page'
import { TableWrapper, Td, Thead, Tr } from 'components/table'
import { withAPI } from 'hocs'
import { useDidMountEffect } from 'hooks'
import useLoadCompanies from 'hooks/api/useLoadCompanies'
import _ from 'lodash'
import WithPermission from 'modules/with-permission'
import React, { Fragment, useState, useRef } from 'react'
import { getFormattedCompArr, scrollToTop } from 'utils'
import { DVIRQuestionDropDown } from '.'
import useLoadDVIRQuestions from '../hooks/useLoadDVIRQuestions'
import { CreateEditCategory, CreateEditItems } from './modals'

const columnList = ['Name', 'Company', 'Date'];

const DVIRConfig = ({ httpRequest }) => {

  const [isCreateCategoryOpen, setIsCreatedCategoryOpen] = useState(false);
  const [isCreateItemsOpen, setIsCreateItemsOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const { data: companies } = useLoadCompanies(httpRequest);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const { data, isLoading: isQuestionsLoading, loadDVIRQuestions } = useLoadDVIRQuestions(httpRequest, selectedCompanies, false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const tableRef = useRef(null);

  const onCloseCategoryModal = () => {
    setIsCreatedCategoryOpen(false);
    setAction('');
    setSelectedCategory(null);
  }

  const onCloseItemsModal = () => {
    setIsCreateItemsOpen(false);
    setAction('');
    setSelectedCategory(null);
    setSelectedItem(null);
  }

  const menuItems = [
    {
      permissionCheck: ['CompaniesResources', 'DVIR'],
      label: 'Edit',
      callback: (category) => {
        setSelectedCategory(category);
        setAction('Edit');
        setIsCreatedCategoryOpen(true);
      },
    },
    {
      permissionCheck: ['CompaniesResources', 'DVIR'],
      label: 'Delete',
      callback: (category) => {
        setSelectedCategory(category);
        setAction('delete-category');
        setIsConfirmationModalOpen(true);
      },
    },
    {
      permissionCheck: ['CompaniesResources', 'DVIR'],
      label: 'Create Items',
      callback: (category) => {
        setSelectedCategory(category);
        setIsCreateItemsOpen(true);
        setAction('Create');
      },
    },
  ];

  const dropdownMenuItems = [
    {
      permissionCheck: ['CompaniesResources', 'DVIR'],
      label: 'Edit',
      callback: (item) => {
        setSelectedItem(item);
        setAction('Edit');
        setIsCreateItemsOpen(true);
        setSelectedCategory(item?.category);
      },
    },
    {
      permissionCheck: ['CompaniesResources', 'DVIR'],
      label: 'Delete',
      callback: (item) => {
        setSelectedItem(item);
        setAction('delete-item');
        setIsConfirmationModalOpen(true);
      },
    },
  ]

  const onDeleteItems = async () => {

    const url = action === 'delete-category'
      ? `/dvir-categories/${selectedCategory?.id}`
      : `/dvir-items/${selectedItem?.id}`;
    const response = await httpRequest({
      url,
      method: 'delete'
    })
      .catch((error) => {
        setErrors(error?.response?.data);
        scrollToTop(tableRef);
      })

    if (response) {
      loadDVIRQuestions()
    }
  }

  const debouncedCompanySelect = _.debounce((company) => {
    setSelectedCompanies(company);
  }, 500)

  useDidMountEffect(() => {
    setSelectedCompanies(getFormattedCompArr(companies));
  }, [companies])

  return (
    <PageWrapper>
      <CreateEditCategory
        open={isCreateCategoryOpen}
        setOpen={onCloseCategoryModal}
        action={action}
        category={selectedCategory}
        refreshData={loadDVIRQuestions}
        companies={companies}
      />
      <CreateEditItems
        open={isCreateItemsOpen}
        setOpen={onCloseItemsModal}
        action={action}
        category={selectedCategory}
        refreshData={loadDVIRQuestions}
        item={selectedItem}
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        setOpen={setIsConfirmationModalOpen}
        action="Delete"
        listName="category"
        actionFunc={async () => {
          await onDeleteItems();
          action === 'delete-category'
            ? setSelectedCategory(null)
            : setSelectedItem(null);
          setAction('');
          setIsConfirmationModalOpen(false);
        }}
      />
      <PageHeaderWrapper>
        <PageTitle title="DVIR Config" withFAQsPage />
        <WithPermission permissionsCheck={['CompaniesResources', 'DVIR']}>
          <PageHeaderBtn label="Create" onClick={() => {setIsCreatedCategoryOpen(true); setAction('Create')}} />
        </WithPermission>
      </PageHeaderWrapper>
      <PageHeaderDataFilter>
        {_.isArray(companies) && companies.length > 1 && (
          <div className="md:w-max w-full">
            <MultipleSelector
              options={getFormattedCompArr(companies)}
              defaultSelectedOptions={getFormattedCompArr(companies)}
              onChangeSelectedOptions={debouncedCompanySelect}
            />
          </div>
        )}
      </PageHeaderDataFilter>
      <TableWrapper ref={tableRef}>
        {!_.isEmpty(errors) && (
          <ValidationsErrors errors={errors} />
        )}
        <PageLoadingIndicator loading={isQuestionsLoading} />
        {data?.length === 0 && !isQuestionsLoading && (<NoResults />)}
        <table className="divide-y divide-gray-200 overflow-x-hidden overflow-y-visible w-full">
          {data?.length > 0 && (
            <Thead columnList={columnList} />
          )}
          <tbody className={`w-full divide-y ${isQuestionsLoading ? 'opacity-50' : ''}`}>
            {!isQuestionsLoading && data?.length > 0 && data.map((category) => (
              <Fragment key={category?.id}>
                <Tr>
                  <Td className="pl-7 py-3">{category?.name}</Td>
                  <Td>{category?.company?.name}</Td>
                  <Td>{getFormattedDateAPI(category?.created_at)}</Td>
                  <Td className="text-right pl-2 py-3">
                    <DotsDropdown
                      id={category}
                      menuItems={category?.is_custom ? menuItems : [menuItems[1]]}
                    />
                  </Td>
                </Tr>
                <DVIRQuestionDropDown
                  categoryId={category?.id}
                  menuItems={dropdownMenuItems}
                />
              </Fragment>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </PageWrapper>
  )
}

export default _.flowRight([withAPI])(DVIRConfig);