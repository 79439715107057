import { PageHeaderBtn, PageHeaderWrapper, PageTitle, PageWrapper, PageWrapperBtn } from 'components/page';
import { TableWrapper } from 'components/table';
import ModalFaq from 'components/modal/faq';
import React, { useState } from 'react';
import { useLoadGroupedFAQs } from './hooks';
import _ from 'lodash';
import { withAPI } from 'hocs';
import { FAQDetailModal } from './modal';
import FaqSection from './faq-section';
import { PageLoadingIndicator } from 'components/loading-indicator';
import WithPermission from 'modules/with-permission';
import ModalDeleteItem from 'components/modal/delete-item';
import PageWrapperDataFilter from 'components/page/page-wrapper-data-filter';
import PageSearch from 'components/page/page-search';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { NoResults } from 'components/notifications';

export const FAQActions = {
  create: 'Create',
  edit: 'Edit',
  delete: 'Delete',
  details: 'Details',
}

const KnowledgeBase = ({ httpRequest }) => {

  const location = useLocation();
  const history = useHistory();

  const [isModalFAQOpen, setIsModalFAQOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);


  const [action, setAction] = useState(FAQActions.create);
  const [search, setSearch] = useState('');
  const [hash, setHash] = useState(null);
  const [sectionRefs, setSectionRefs] = useState([]);

  const { data, loadGroupedFAQs, isLoading } = useLoadGroupedFAQs(httpRequest, search);
  const [selectedFAQ, setSelectedFAQ] = useState({});

  const menuItems = [
    {
      permissionCheck: ['AnyResource'],
      label: 'Edit',
      callback: (faq) => {
        setAction(FAQActions.edit);
        setIsModalFAQOpen(true);
        setSelectedFAQ(faq);
      },
    },
    {
      permissionCheck: ['AnyResource'],
      label: 'Delete',
      callback: (faq) => {
        setAction(FAQActions.delete);
        setIsModalDeleteOpen(true);
        setSelectedFAQ(faq);
      },
    },
    {
      permissionCheck: ['AnyResource', 'CompaniesResources'],
      label: 'Details',
      callback: (faq) => {
        onClickDetails(faq);
      },
    },
  ];

  const debouncedSearch = _.debounce((search) => {
    if (search.length !== 1) {
      setSearch(search);
    }
  }, 500);

  const onClickDetails = (faq) => {
    setAction(FAQActions.details);
    setIsModalDetailsOpen(true);
    setSelectedFAQ(faq);
  }

  useEffect(() => {
    const { id } = qs.parse(location?.search);
    const { hash } = location;
    if (hash) {
      setHash(hash);
    }
    if (id) {
      setIsModalDetailsOpen(true);
      setSelectedFAQ({ id });
      setAction(FAQActions.details);
      history.push('/knowledge-base');
    }
  }, [location, history, debouncedSearch]);

  useEffect(() => {
    if (!_.isEmpty(data) && hash && sectionRefs?.length > 0) {
      const ref = _.find(sectionRefs, (ref) => ref.current?.id === hash);
      if (ref) {
        setHash('');
        history.push('/knowledge-base');
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [data, hash, sectionRefs, history])

  useEffect(() => {
    return () => debouncedSearch.cancel();
  }, [debouncedSearch])

  useEffect(() => {
    setSectionRefs([]);
  }, [isLoading])

  return (
    <PageWrapper>
      {isModalFAQOpen && (
        <ModalFaq
          open={isModalFAQOpen}
          setOpen={setIsModalFAQOpen}
          refreshItems={async () => {
            await loadGroupedFAQs();
            setIsModalFAQOpen(false);
          }}
          action={action}
          faq_id={selectedFAQ?.id}
          faq={selectedFAQ}
          companies={[]}
        />
      )}
      {
        isModalDeleteOpen && (
          <ModalDeleteItem
            open={isModalDeleteOpen}
            setOpen={(opn) => { setIsModalDeleteOpen(opn); setAction(FAQActions.create); }}
            itemId={selectedFAQ?.id}
            listName="FAQ"
            deleteURL="faq"
            refreshItems={loadGroupedFAQs}
          />
        )
      }
      {
        isModalDetailsOpen && (
          <FAQDetailModal
            open={isModalDetailsOpen}
            setOpen={(opn) => { setIsModalDetailsOpen(opn); setAction(FAQActions.create); }}
            faqId={selectedFAQ?.id}
          />
        )
      }
      <PageHeaderWrapper>
        <PageLoadingIndicator loading={isLoading} />
        <PageTitle title="Knowledge Base" />

        <WithPermission permissionsCheck={['AnyResource']}>
          <PageWrapperBtn>
            <PageHeaderBtn label="Create" onClick={() => { setAction(FAQActions.create); setIsModalFAQOpen(true) }} />
          </PageWrapperBtn>
        </WithPermission>

      </PageHeaderWrapper>
      <PageWrapperDataFilter>
        <PageSearch search={search} onChange={debouncedSearch} />
      </PageWrapperDataFilter>
      <TableWrapper>
        <div className="flex flex-col gap-5">
          {data?.map((section) => (
            <FaqSection
              setSectionRefs={setSectionRefs}
              key={section?.label}
              section={section}
              menuItems={menuItems}
              onClickDetails={onClickDetails}
            />
          ))}
        </div>
        <NoResults centerContent isVisible={!isLoading && data?.length === 0} />
      </TableWrapper>
    </PageWrapper>
  )
}

export default _.flowRight([withAPI])(KnowledgeBase);