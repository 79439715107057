import React from 'react';
import PropTypes from 'prop-types';
import FAQInformationTooltip from 'modules/faqs/faq-information-tooltip';

const PageTitle = ({ title, subtitle, withFAQsPage, customFAQHash }) => (
  <div className="mt-2 mb-1.5 pb-px flex-1 min-w-0 flex flex-col w-full">
    <h1 className="text-2xl font-semibold leading-6 text-gray-900">
      <span className="relative">
        {title}
        {withFAQsPage && (
          <FAQInformationTooltip hash={customFAQHash || title} className="w-5 h-5 absolute bottom-0.5 -right-5" />
        )}
      </span>
    </h1>
    <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

PageTitle.defaultProps = {
  subtitle: '',
};

export default PageTitle;
