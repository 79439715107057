import React, { useEffect, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { PageWrapper } from 'components/page';
import { TableWrapper } from 'components/table';
import _ from 'lodash';
import { withAPI } from 'hocs';
import { PageLoadingIndicator } from 'components/loading-indicator';
import { ValidationsErrors } from 'components/notifications';

const UserChangeParentCompany = ({ httpRequest }) => {
  const location = useLocation();
  const history = useHistory();
  const token = QueryString.parse(location.search)?.change_token;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onConfirmToken = useCallback(async () => {
    setIsLoading(true);
    await httpRequest({
      method: 'post',
      url: '/users/check-duplicate/confirm',
      data: { change_token: token },
      noToken: true,
    }).then(() => {
      localStorage.clear();
      history.push('/login');
    })
    .catch((error) => {
      setErrors(error.response.data);
    })
    .finally(() => {
      setIsLoading(false);
    })
  }, [history, httpRequest, token])

  useEffect(() => {
    onConfirmToken();
  }, [location, onConfirmToken]);

  return (
    <div className="pl-5 w-full h-full">
      <PageWrapper>
        <TableWrapper>
          {!_.isEmpty(errors) && (
            <ValidationsErrors errors={errors} />
          )}
          <PageLoadingIndicator loading={isLoading} />
        </TableWrapper>
      </PageWrapper>
    </div>
  )
}

export default _.flowRight([withAPI])(UserChangeParentCompany);