import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class FormNativeSelect extends PureComponent {
  render() {
    const {
      label, id, isRequired, value, handleValue,
      colspan, values,
    } = this.props;

    return (
      <div className={`sm:col-span-${colspan} flex flex-col justify-between`}>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
          {isRequired && (
            <sup className="text-red-regular">*</sup>
          )}
        </label>
        <div className="mt-1">
          <select
            value={value || ''}
            id={id}
            name={id}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                  block w-full sm:text-sm border-gray-300 rounded-md border "
            onChange={(e) => handleValue(id, e.target.value)}
          >
            {_.isArray(values) && values.map((item) => (
              <option
                value={item.id}
                key={item.id === '' ? id : item.id}
                disabled={item.id === ''}
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}
FormNativeSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  handleValue: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  colspan: PropTypes.number,
};

FormNativeSelect.defaultProps = {
  isRequired: false,
  colspan: 3,
};

export default FormNativeSelect;
