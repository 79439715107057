import React from 'react';
import PropTypes from 'prop-types';

const PageLoadingIndicator = ({ loading }) => (loading ? (
  <div data-testid="loader" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
    <div className="animate-spin border-b-2 rounded-full h-10 w-10 border-gray-900" />
  </div>
) : null);

PageLoadingIndicator.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default PageLoadingIndicator;
