/* eslint max-len: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import withUserInfo from '../../hocs/with-user-info';
import { checkNavPermission, hasPermission } from '../../utils';
import { navigation } from 'components/navigation';

const WithPermission = ({
  permissions,
  permissionsCheck,
  children,
  onRoute,
}) => {
  const intentAllowed = _.isEmpty(permissionsCheck) || hasPermission(permissionsCheck, permissions);
  const filteredNavigation = navigation.filter((nav) => checkNavPermission(nav, permissions));
  const firstTabRef = _.get(filteredNavigation, '[0].href', '/home');
  if (!intentAllowed) return onRoute ? <Redirect to={firstTabRef}/> : null;
  return children;
};

WithPermission.propTypes = {
  permissions: PropTypes.array.isRequired,
  permissionsCheck: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  onRoute: PropTypes.bool,
};

WithPermission.defaultProps = {
  onRoute: false,
};

export default compose(
  withUserInfo,
)(WithPermission);
