import moment from 'moment';

export const getFormattedDate = (date) => {
  const formattedDate = date instanceof Date
    ? moment(date).format('MM-DD-YYYY')
    : moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY');
  return formattedDate !== 'Invalid date' ? formattedDate : '';
};

export const getFormattedDateAPI = (date) => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return formattedDate !== 'Invalid date' ? formattedDate : '';
};

export const getFormattedFullDate = (date, format = 'MM-DD-YYYY hh:mm:ss') => {
  const formattedDate = moment(date).format(format);
  return formattedDate !== 'Invalid date' ? formattedDate : '';
};

export const getDate = (date) => {
  if (date === '0000-00-00') return null;
  return (date ? moment(date).toDate() : new Date());
};

export const subtractWeek = (date) => {
  if (date === '0000-00-00') return null;
  return (date ? moment(date).subtract(1, 'w').toDate() : new Date());
};

export const getDateFromYear = (year) => (year ? moment(year, 'YYYY').toDate() : new Date());

export const setDate = (date) => moment(date).format('YYYY-MM-DD');

// weekDay (0 to 6) means Sunday(0) to Saturday(6)
export const nextWeekDay = (date, weekDay) => (new Date(
  date.setDate(date.getDate() + ((weekDay + (7 - date.getDay())) % 7)),
));

export const isFriday = (date) => date?.getDay() === 5;

export const getDayDiff = (startDate, endDate = new Date()) => {
  const msInDay = 24 * 60 * 60 * 1000;

  return Math.round(Math.abs((endDate.getTime() - getDate(startDate).getTime())) / msInDay);
};

export const getFirstMonthDay = () => moment().startOf('month').toDate();

export const getLastMonthDay = () => moment().endOf('month').toDate();
