import { PageLoadingIndicator } from 'components/loading-indicator'
import React from 'react'
import { PageWrapper } from '.';

const PageLoading = () => (
  <PageWrapper>
    <PageLoadingIndicator loading={true} />
  </PageWrapper>
);

export default PageLoading;