import ClearIndicator from 'components/clear-interval-indicator/ClearIndicator';
import DropdownIndicator from 'components/drop-down-indicator/DropdownIndicator';
import MenuSelectList from 'components/menu-select-list/MenuSelectList';
import selectContainer from 'components/select-container';
import { defaultStyles } from 'components/select-custom-styles';
import selectOptionsCheckbox from 'components/select-options-checkbox';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { onChangeMultipleSelect } from 'utils';
import Select from 'react-select';

const MultipleSelector = ({
  options,
  defaultSelectedOptions,
  onChangeSelectedOptions,
  isAllSelected = true,
  maxMenuHeight = 325,
}) => {
  const [selectedData, setSelectedData] = useState(defaultSelectedOptions);
  const [isAllData, setIsAllData] = useState(isAllSelected);

  const onChangeSelect = (opts) => {
    const { selectedCompanies, isAllComp } = onChangeMultipleSelect(opts, isAllData, options);
    setIsAllData(isAllComp);
    setSelectedData(selectedCompanies);
    onChangeSelectedOptions(selectedCompanies);
  }

  return (
    <Select
      value={selectedData}
      options={options}
      onChange={onChangeSelect}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      selectProps={{ isLimit: false }}
      components={{
        Option: selectOptionsCheckbox,
        ValueContainer: selectContainer,
        MenuList: MenuSelectList,
        DropdownIndicator,
        ClearIndicator,
      }}
      maxMenuHeight={maxMenuHeight}
      className="block w-full sm:text-sm rounded-md"
      name="totals_companies"
      placeholder="Select company..."
      classNamePrefix="select"
      styles={defaultStyles}
    />
  )
}

MultipleSelector.propTypes = {
  options: PropTypes.array.isRequired,
  defaultSelectedOptions: PropTypes.array.isRequired,
  onChangeSelectedOptions: PropTypes.func.isRequired,
  maxMenuHeight: PropTypes.number,
};

MultipleSelector.defaultProps = {
  maxMenuHeight: 325,
};

export default MultipleSelector;