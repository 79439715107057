import React from 'react';
import PropTypes from 'prop-types';

const PageWrapperBtn = ({ children }) => (
  <div className="flex sm:flex-row flex-col gap-2">
    {children}
  </div>
);

PageWrapperBtn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default PageWrapperBtn;
