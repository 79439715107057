import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DotsDropdown from 'components/dots-dropdown';
import { teamTypes } from 'components/modal/add-safety-alert';

const statuses = {
  new: 1,
  resolved: 2,
}

const DVIRRow = ({ data, onClickView, menuItems, refreshItems, httpRequest }) => {
  const date = data?.created_at ? moment(data?.created_at).format('yyyy-MM-DD HH:mm:ss') : ''
  const pass = data?.dvir_items?.filter((item) => ! item?.pass).length === 0;
  const teamTypeLabel = teamTypes.find((i) => i.value === data?.user?.driver?.team_type);

  const menuItem = {
    permissionCheck: ['CompaniesResources'],
    label: data?.status?.status === 1 ? 'Mark as Resolved' : 'Mark as Unresolved',
    condition: ({ dvir_items }) => {
      return dvir_items?.filter((item) => !item?.pass)?.length !== 0;
    },
    callback: async () => {
      await onChangeStatus();
    },
  }

  const onChangeStatus = async () => {
    const response = await httpRequest({
      url: `/truck-checks/${data?.id}/status`,
      method: 'post',
      data: { status: data?.status?.status === statuses.new ? statuses.resolved : statuses.new  },
    })

    if (response) {
      refreshItems();
    }
  }

  return (
    <tr className={`text-sm ${pass ? '' : 'text-red-600 font-semibold'}`}>
      <td className="px-2 py-3">{data?.truck_no}</td>
      <td className="px-2 py-3">{data?.user?.name}</td>
      <td className="px-2 py-3">{date}</td>
      <td className="px-2 py-3">{data?.type?.label}</td>
      <td className="px-2 py-3">{teamTypeLabel?.label || ''}</td>
      <td className="px-2 py-3">{data?.user?.driver?.hub?.name}</td>
      <td className="px-2 py-3">{pass ? "Pass" : `Fail (${data?.status?.label})`}</td>
      <td className="px-2 py-3">
        <button
          type="button"
          onClick={() => onClickView(data)}
          className="text-indigo-600 hover:text-indigo-900"
        >
          View
        </button>
      </td>
      <td className="px-2 py-3 text-right">
        <DotsDropdown menuItems={[...menuItems, menuItem]} id={data} />
      </td>
    </tr>
  )
}

DVIRRow.propTypes = {
  data: PropTypes.object.isRequired,
  onClickView: PropTypes.func.isRequired,
}

export default DVIRRow