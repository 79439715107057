import { PageLoadingIndicator } from 'components/loading-indicator'
import LocationMap from 'components/modal/location-map'
import { MultipleSelector } from 'components/multiple-selector'
import { NoResults, ValidationsErrors } from 'components/notifications'
import { PageHeaderWrapper, PageTitle, PageWrapper, PageWrapperBtn } from 'components/page'
import PageSearch from 'components/page/page-search'
import PageWrapperDataFilter from 'components/page/page-wrapper-data-filter'
import Pagination from 'components/pagination'
import { TableWrapper, Thead } from 'components/table'
import { withAPI } from 'hocs'
import { useDidMountEffect } from 'hooks'
import useLoadCompanies from 'hooks/api/useLoadCompanies'
import _ from 'lodash'
import { ClockInOut } from 'modules/clock-in-out'
import { useLoadClocks } from 'modules/clock-in-out/hooks'
import WithPermission from 'modules/with-permission'
import React, { useState } from 'react'
import { getFormattedCompArr, getFullName } from 'utils'

const columnList = ['Driver', 'Clock In', 'In Automatic', 'Clock Out', 'Out Automatic', 'Is Notified', 'Is Force Out', 'Location In', 'Location Out'];

const Clocks = ({ httpRequest }) => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { data: companies } = useLoadCompanies(httpRequest);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const { data, isLoading, pagination, loadClocks } = useLoadClocks(httpRequest,
    { page: currentPage, companies: selectedCompanies, search }
  );
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [error, setError] = useState({});

  const debouncedCompanySelect = _.debounce((company) => {
    setSelectedCompanies(company);
    setCurrentPage(1);
  }, 500)

  useDidMountEffect(() => {
    setSelectedCompanies(getFormattedCompArr(companies));
    return () => debouncedCompanySelect.cancel();
  }, [companies])

  const debouncedSearch = _.debounce((search) => {
    if (search.length !== 1) {
      setSearch(search);
    }
  }, 500);

  return (
    <PageWrapper>
      <PageHeaderWrapper>
        <PageTitle title="Clocks" withFAQsPage />
        
        <WithPermission permissionsCheck={['CanDrive']} >
          <PageWrapperBtn>
            <ClockInOut setError={setError} refreshData={loadClocks} />
          </PageWrapperBtn>
        </WithPermission>

      </PageHeaderWrapper>
      <PageWrapperDataFilter>
        <PageSearch search={search} onChange={debouncedSearch} />
        {_.isArray(companies) && companies.length > 1 && (
          <div className="md:w-max w-full" data-testid="companySelector">
            <MultipleSelector
              options={getFormattedCompArr(companies)}
              defaultSelectedOptions={getFormattedCompArr(companies)}
              onChangeSelectedOptions={debouncedCompanySelect}
            />
          </div>
        )}
      </PageWrapperDataFilter>
      <LocationMap
        open={isMapOpen}
        setOpen={() => { setIsMapOpen(false); setSelectedLocation({ lat: '', lng: '' }); }}
        location={[ selectedLocation?.lat, selectedLocation?.lng ]}
      />
      <TableWrapper>
        {!_.isEmpty(error) && (
          <ValidationsErrors errors={error} />
        )}

        {data?.length === 0 && selectedCompanies?.length > 0 && !isLoading && (<NoResults />)}
        {selectedCompanies?.length === 0 && (<p className="text-sm">Select company</p>)}
        <PageLoadingIndicator loading={isLoading} />
        <table className="divide-y divide-gray-200 overflow-x-hidden overflow-y-visible w-full">
          {data?.length > 0 && (
            <Thead columnList={columnList} />
          )}
          <tbody className={`w-full divide-y ${isLoading ? 'opacity-50' : ''}`}>
            {data?.length > 0 && data.map((item) => (
              <tr key={item?.id} className="text-sm">
                <td className="px-3 py-2">{`${getFullName(item?.user)} ${item?.user?.driver?.driver_no || ''}`}</td>
                <td className="px-3 py-2">{item?.in_at}</td>
                <td className="px-3 py-2">{item?.truck_check_in ? "Yes" : "No"}</td>
                <td className="px-3 py-2">{item?.out_at}</td>
                <td className="px-3 py-2">{item?.truck_check_out ? "Yes" : "No"}</td>
                <td className="px-3 py-2">{item?.is_notified ? "Yes" : "No"}</td>
                <td className="px-3 py-2">{item?.is_automatic ? "Yes" : "No"}</td>
                <td className="p-2">
                  {(item?.in_lat !== null && item?.in_lng !== null) && (
                      <button
                        type="button"
                        onClick={() => {setSelectedLocation({ lat: item?.in_lat, lng: item?.in_lng}); setIsMapOpen(true)}}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        View
                      </button>
                    )
                  }
                </td>
                <td className="p-2">
                  {(item?.out_lat !== null && item?.out_lng !== null) && (
                      <button
                        type="button"
                        onClick={() => {setSelectedLocation({ lat: item?.out_lat, lng: item?.out_lng}); setIsMapOpen(true)}}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        View
                      </button>
                    )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          {...pagination}
          currentPage={currentPage}
          onChange={(page) => setCurrentPage(page)}
        />
      </TableWrapper>
    </PageWrapper>
  )
}

export default _.flowRight([withAPI])(Clocks);